import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// custom components
import Button from "components/CustomButtons/Button.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Loading from "sections/Loading.jsx";
// external modules
import YouTube from 'react-youtube';
import { Helmet } from "react-helmet";
import { navigate } from 'gatsby'
import Firebase from "../firebase";
// Auth
import { isAuthenticated, getUserEmail, setCallbackLink } from 'Auth/AuthService';
// styles
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";
import 'react-circular-progressbar/dist/styles.css';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Drawer from '@material-ui/core/Drawer';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  paper: {
    background: "#2a283e"
  },
  expansion: {
    backgroundColor: "#2a283e"
  },
  spacer: {
    height: 75
  },
  listItemColor: {
    color: "#fff"
  },
  ...headersStyle
}

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: false,
      openLeft: false,
      openRight: false,
      lessonList: [],
      prevSlug: "",
      nextSlug: "/",
      sectionDescription: "",
      points: 0,
      userPoints: 0,
      completed: false,
      courseName: "",
      title: "",
      email: getUserEmail()
    };
  }

  componentDidMount() {
    if (!isAuthenticated()) {
      const { pathname } = this.props.location;
      setCallbackLink(pathname);
      navigate("/login")
    }
    else {
      const { pageContext } = this.props;
      const { email } = this.state;
      var lessonList = [];
      var prev = pageContext.order - 1;
      var next = pageContext.order + 1;

      Firebase
        .collection("lessons")
        .where("courseName", "==", pageContext.courseName)
        .where("title", "==", pageContext.title)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const { title, courseName, points } = doc.data();
            Firebase
              .collection("completed-lessons")
              .where("courseName", "==", pageContext.courseName)
              .where("title", "==", pageContext.title)
              .where("user", "==", email)
              .limit(1)
              .get()
              .then(snapshot => {
                var count = 0;
                snapshot.forEach(lesson => {
                  count += 1;
                })
                if (count > 0) {
                  this.setState({ completed: true });
                }
              })
            this.setState({ points, courseName, title });
          })
        })
      Firebase
        .collection("users")
        .doc(email)
        .get()
        .then(doc => {
          this.setState({ userPoints: doc.data().points });
        })
      Firebase
        .collection("lessons")
        .where("courseName", "==", pageContext.courseName)
        .where("section", "==", pageContext.section)
        .orderBy("order")
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const { title, slug, order } = doc.data();
            lessonList.push({
              title,
              slug,
              order
            })
            if (order === prev) {
              this.setState({ prevSlug: slug })
            }
            if (order === next) {
              this.setState({ nextSlug: slug })
            }
          })
          this.setState({ lessonList })
        })
      Firebase
        .collection("courses")
        .where("title", "==", pageContext.courseName)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const { sectionInfo, sections, slug } = doc.data();
            for (var key in sections) {
              if (sections[key] === pageContext.section) {
                this.setState({ sectionDescription: sectionInfo[key] })
              }
            }
            this.setState({ courseSlug: slug })
          })
        })
    }
  }

  toggleDrawer(side) {
    if (side === "left") {
      this.setState({ openLeft: !this.state.openLeft });
    }
    else {
      this.setState({ openRight: !this.state.openRight });
    }
  }

  completeLesson() {
    const { nextSlug, points, title, courseName, email, userPoints, lessonList, completed, courseSlug } = this.state;
    const { pageContext } = this.props;

    if (points === 0 || title === "" || courseName === "" || nextSlug === "" || courseSlug === "") {
      return;
    } else if (completed && pageContext.order === lessonList.length) {
      navigate(`/${courseSlug}`)
    } else if (completed) {
      navigate(nextSlug)
    } else {
      var batch = Firebase.batch();
      var addCompletion = Firebase.collection("completed-lessons").doc();
      var updatePoints = Firebase.collection("users").doc(email);
      batch.set(addCompletion, {
        dateCompleted: new Date(),
        courseName,
        title,
        user: email
      })
      batch.update(updatePoints, {
        points: userPoints + points
      })
      batch.commit().then(() => {
        if (pageContext.order === lessonList.length) {
          navigate(`/${courseSlug}`)
        }
        else {
          navigate(nextSlug)
        }
      })
    }
  }

  slugify(string) {
    return string.toString().toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  render() {
    const { classes, pageContext } = this.props;
    const { lessonList, prevSlug, sectionDescription, completed, nextSlug } = this.state;

    return (
      <div>
        {isAuthenticated() ?
          <div style={{ backgroundColor: "#212121", height: "100vh"}}>
            <Helmet>
              <title>{`${pageContext.title} | Able University`}</title>
            </Helmet>
            <Header
              headerLink={`/courses/${this.slugify(pageContext.courseName)}`}
              brand={`${pageContext.courseName} | ${pageContext.section}`}
              color="dark"
              links={<HeaderLinks dropdownHoverColor="dark" />}
              fixed
            />
            <Drawer open={this.state.openLeft} classes={{ paper: classes.paper }} onClose={() => this.toggleDrawer("left")}>
              <div style={{ "width": 360 }}>
                <List>
                  <ListItem>
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>{pageContext.courseName}</h5>
                  </ListItem>
                  <Divider style={{ height: 3 }} />
                  <ListItem>
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>{pageContext.section}</h5>
                  </ListItem>
                  <ListItem>
                    <p style={{ color: "#fff" }}>{sectionDescription}</p>
                  </ListItem>
                  <Divider style={{ height: 3 }} />
                  {lessonList.map(lesson => {
                    return (
                      <ListItem
                        button
                        style={{ marginTop: 5, marginBottom: 5 }}
                        onClick={() => navigate(lesson.slug)}
                        key={lesson.title}
                      >
                        <ListItemText classes={{ primary: classes.listItemColor }}>
                          {`${lesson.order}. ${lesson.title}`}
                        </ListItemText>
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            </Drawer>
            <Drawer anchor="right" open={this.state.openRight} classes={{ paper: classes.paper }} onClose={() => this.toggleDrawer("right")}>
              <div style={{ "width": 360 }}>
                <h3 style={{ color: "#fff", fontWeight: "bold", marginLeft: 24, marginBottom: 25 }}>Help Options</h3>
                <ExpansionPanel className={classes.expansion}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>Typos or Corrections</h5>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <p style={{ color: "#fff" }}>
                      If you find that this article is missing information or may be incorrect, please email Jennifer Baker
                      at jbaker@ablehomecarellc.com or Victoria perlman at vperlman@ablehomecarellc.com
                    </p>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className={classes.expansion}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>Bugs or Issues</h5>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <p style={{ color: "#fff" }}>
                      If you are having trouble with this webpage please email ebeazer@ablehomecarellc.com with the details
                      of your issue
                    </p>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className={classes.expansion}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>Suggestions</h5>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <p style={{ color: "#fff" }}>
                      We are always looking for suggestions to make this website better.  If you feel like you have something that
                      may improve the website please send an email to ebeazer@ablehomecarellc.com
                    </p>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </Drawer>
            <div
              className={classes.container}
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                flex: 1,
                width: "100%",
                alignItems: "center"
              }}
            >
              <YouTube
                videoId={pageContext.video}
                opts={{
                  height: 563,
                  width: 1000
                }}
              />
              <div style={{ position: "fixed", bottom: 0, width: "100vw", backgroundColor: "#212121", marginBottom: 10}}>
                <GridContainer>
                  <GridItem sm={4}>
                    <Button
                      simple
                      onClick={() => this.toggleDrawer("left")}
                    >
                      <span style={{ paddingRight: 5 }}>
                        <i className="fas fa-bars" />
                      </span>
                      <span style={{ paddingLeft: 5, fontWeight: "bold" }}>
                        {`${pageContext.order}. ${pageContext.title}`}
                      </span>
                    </Button>
                  </GridItem>
                  <GridItem sm={1}>
                    <Button
                      block
                      disabled={pageContext.order === 1}
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={() => prevSlug !== "" ? navigate(prevSlug) : null}
                    >
                      Previous
                    </Button>
                  </GridItem>
                  <GridItem
                    sm={2}
                    style={{
                      display: "flex",
                      height: "100%",
                      flex: 1,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {lessonList.length !== 0 && (
                      <h5 style={{ color: "#fff", fontWeight: "bold", textAlign: "center" }}>{`${pageContext.order}/${lessonList.length}`}</h5>
                    )}
                  </GridItem>
                  <GridItem sm={1}>
                    <Button
                      block
                      onClick={() => this.completeLesson()}
                      color="primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                    >
                      {pageContext.order === lessonList.length ? "Finish Setion" : "Next"}
                    </Button>
                  </GridItem>
                  <GridItem sm={2} />
                  <GridItem sm={2}>
                    <Button
                      simple
                      onClick={() => this.toggleDrawer("right")}
                      style={{ float: "right" }}
                    >
                      <span style={{ paddingRight: 5 }}>
                        <i className="far fa-question-circle" />
                      </span>
                      <span style={{ paddingLeft: 5, fontWeight: "bold" }}>
                        Get Help
                      </span>
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        :
        <Loading />
        }
      </div>
    )
  }
};

export default withStyles(styles)(HomePage);
